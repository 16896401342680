import {applyPatch, flow, types} from "mobx-state-tree"
import axios from "axios"
import {deviceDetect} from "react-device-detect"

const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN
const REFRESH_TOKEN = process.env.REACT_APP_REFRESH_TOKEN

axios.defaults.baseURL = process.env.REACT_APP_HOST + "/api.v1"

export default types
    .model('neutronSSO', {
        identifier: types.optional(types.identifier, 'user'),
        id: types.maybeNull(types.number),
        username: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        role: types.maybeNull(types.string)
    })
    .volatile(self => ({
        loading: true,
    }))
    .actions((self) => ({
        afterCreate() {
            Boolean(localStorage.getItem(ACCESS_TOKEN)) && this.user()
        },
        setLoading(bool) {
            console.log(self['$treenode'].type.name, 'setLoading', bool ? 'Запрос - стартовал' : 'Запрос - окончен')
            self.loading = bool
        },
        initialize(payload) {
            const {accessToken, refreshToken, ...snapshot} = payload
            console.log(self['$treenode'].type.name, 'initialize', snapshot)
            if (Boolean(accessToken))
                localStorage.setItem(ACCESS_TOKEN, accessToken)
            else if (!Boolean(localStorage.getItem(ACCESS_TOKEN)))
                throw new Error('Нет токена доступа')
            if (Boolean(refreshToken))
                localStorage.setItem(REFRESH_TOKEN, refreshToken)
            else if (!Boolean(localStorage.getItem(REFRESH_TOKEN)))
                throw new Error('Нет токена обновления')
            applyPatch(self, {op: 'replace', path: '', value: snapshot})
        },
        async join(username, password) {
            this.setLoading(true)
            const device = {
                ...deviceDetect(window.navigator.userAgent),
                tz: Intl.DateTimeFormat()['resolvedOptions']().timeZone,
                width: window.screen.width,
                height: window.screen.height,
                resolution: window.devicePixelRatio
            }
            await axios
                .post('/join', {
                    username: username.trim(),
                    password: password.trim(),
                    device: device
                })
                .then(response => response.data)
                .then(this.initialize)
                .finally(() => this.setLoading(false))
        },
        async login(username, password) {
            this.setLoading(true)
            await axios
                .post('/login', {username: username.trim(), password: password.trim()})
                .then(response => response.data)
                .then(this.initialize)
                .finally(() => this.setLoading(false))
        },
        user: flow(function* () {
            const {setLoading, refresh, initialize} = self
            setLoading(true)
            try {
                const response = yield axios.get('/user', {headers: {Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`}})
                initialize(response.data)
            } catch (error) {
                switch (error.response.status) {
                    case 422:
                        refresh()
                        console.log(self['$treenode'].type.name, 'user', 'refresh token')
                        break
                    default:
                        console.log(self['$treenode'].type.name, 'user', error)
                        break
                }
            } finally {
                setLoading(false)
            }
        }),
        refresh: flow(function* () {
            const {setLoading, initialize} = self
            setLoading(true)
            try {
                const response = yield axios.get('/refresh', {headers: {Authorization: `Bearer ${localStorage.getItem(REFRESH_TOKEN)}`}})
                initialize(response.data)
            } catch (error) {
                console.log(self['$treenode'].type.name, 'refresh', error)
            } finally {
                setLoading(false)
            }
        }),
        logout() {
            localStorage.removeItem(ACCESS_TOKEN)
            localStorage.removeItem(REFRESH_TOKEN)
            applyPatch(self, {op: 'replace', path: '', value: {id: null, username: null, role: null}})
        },
        async waitUser() {
            if (self.loading && localStorage.getItem(ACCESS_TOKEN)) {
                console.log('auth', 'modelAuth', 'Данные: Запрос')
                await new Promise((resolve) => {
                    const intervalId = setInterval(() => {
                        if (!self.loading) {
                            console.log('auth', 'modelAuth', 'Данные: Получены')
                            clearInterval(intervalId)
                            resolve()
                        }
                    }, 100)
                })
            } else
                console.log('auth', 'modelAuth', 'Данные: Присутствуют')
            if (Boolean(self['id'] && localStorage.getItem(ACCESS_TOKEN)))
                return Promise.resolve(self)
            return Promise.resolve(null)
        },
    }))
    .views(self => ({
        get isAuthenticated() {
            return Boolean(self['id'] && localStorage.getItem(ACCESS_TOKEN))
        }
    }))
