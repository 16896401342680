import {initializeApp} from "firebase/app"
// import {getAnalytics} from "firebase/analytics"
import {getMessaging, getToken, onMessage} from "firebase/messaging"
import {deviceDetect} from "react-device-detect"
import axios from "axios"
import {pwaStore} from "../../index"

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const firebaseConfig = {
    apiKey: "AIzaSyDI9fxdrgYBoPJfFLzH3tgD1pbm7gSzVjE",
    authDomain: "bots-work.firebaseapp.com",
    databaseURL: "https://bots-work.firebaseio.com",
    projectId: "bots-work",
    storageBucket: "bots-work.appspot.com",
    messagingSenderId: "97591584557",
    appId: "1:97591584557:web:353e6eabe74445cd3b95a4",
    measurementId: "G-6987H82MN6"
}
// const analytics = getAnalytics(app)
const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)
const sendTokenFCM = () => {
    if (!("Notification" in window)) {
        console.log('neutronPWA', 'notification', "This browser does not support desktop notification")
        return
    }
    const requestPermissionPromise = Notification.permission === "granted"
        ? Promise.resolve(true)
        : Notification.requestPermission()
    requestPermissionPromise
        .then((permission) => {
            if (permission)
                return getToken(messaging, {vapidKey: process.env.REACT_APP_MESSAGING_KEY})
            throw new Error("Permission not granted")
        })
        .then(currentToken => {
            if (currentToken) {
                console.log('neutronPWA', 'notification', "Token от Firebase получен")
                return axios
                    .post("/notification/subscribe",
                        {
                            token: currentToken,
                            device: {
                                ...deviceDetect(window.navigator.userAgent),
                                tz: timezone,
                                width: window.screen.width,
                                height: window.screen.height,
                                resolution: window.devicePixelRatio
                            }
                        }, {headers: {Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)}`}}
                    )
                    .then(() => console.log('neutronPWA', 'notification', "Задача на подписку клиента запущена"))
                    .catch(err => console.log('neutronPWA', 'notification', "Задача на подписку клиента не запущена", err))
            }
            else
                console.log('neutronPWA', 'notification', "Token от Firebase не прошел валидацию")
        })
        .catch(err => {
            console.log('neutronPWA', 'notification', err)
            return err
        })
}

onMessage(messaging, (payload) => {
    const {notification} = payload
    if (Notification.permission === "granted" && "Notification" in window) {
        new Notification("Заголовок уведомления", {
            body: notification.body,
            icon: pwaStore.iconMessage,
        })
    }
    console.log('neutronPWA', 'notification', notification.body)
})

export default sendTokenFCM