import {themeColor} from "../palette"

export const drawer = {
    MuiDrawer: {
        styleOverrides: {
            paper: {
                backgroundColor: themeColor.palette.grey["100"],
            },
        },
    },
}