import {types} from "mobx-state-tree"
import io from "socket.io-client"
import {deviceDetect} from "react-device-detect"
import {enqueueSnackbar} from "notistack"
import i18next from "i18next"

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const {t} = i18next

const neutronSIO = types
    .model('neutronSIO', {
        host: types.string,
    })
    .volatile(self => ({
        client: undefined,
    }))
    .actions(self => ({
        sioDisconnect() {
            const {client} = self
            if (typeof client !== 'undefined') {
                client.disconnect()
                self.client = undefined
                console.log(self['$treenode'].type.name, 'sioDisconnect', 'neutronSIO.client disconnect and remove')
            } else
                console.log(self['$treenode'].type.name, 'sioDisconnect', 'neutronSIO.client empty')
        },
        sioConnect(accessToken) {
            const {host, $treenode} = self
            const config = {
                auth: {
                    token: accessToken,
                    device: {
                        ...deviceDetect(window.navigator.userAgent),
                        tz: timezone,
                        width: window.screen.width,
                        height: window.screen.height,
                        resolution: window.devicePixelRatio
                    }
                },
                transportOptions: {polling: {extraHeaders: {'Authorization': 'Bearer ' + accessToken}}},
                transports: ['websocket'],
                reconnection: true, // включаем переподключение
                reconnectionAttempts: Infinity, // количество попыток переподключения
                reconnectionDelay: 1000, // задержка между попытками переподключения
                reconnectionDelayMax: 5000, // максимальная задержка между попытками переподключения
            }
            console.log($treenode.type.name, 'sioConnect', config)
            self.client = io(host, config)
                .on("connect", (socket) => {
                    console.log(self['$treenode'].type.name, 'connect', socket)
                })
                .on("disconnect", socket => {
                    console.log(self['$treenode'].type.name, 'disconnect', socket)
                })
                .on("error", (error) => {
                    const {message, type} = error
                    console.log(self['$treenode'].type.name, 'error', error)
                    setTimeout(() => enqueueSnackbar(t(message, {'ns': 'network'}), {variant: type}), [200])
                })
                .on("reconnect", (attempt) => {
                    console.log(self['$treenode'].type.name, 'reconnect', attempt)
                })
                .on("reconnect_attempt", (attempt) => {
                    console.log(self['$treenode'].type.name, 'reconnect_attempt', attempt)
                })
                .on("reconnect_error", (error) => {
                    console.log(self['$treenode'].type.name, 'reconnect_error', error)
                })
                .on("reconnect_failed", (arg) => {
                    console.log(self['$treenode'].type.name, 'reconnect_failed', arg)
                })
                .on("ping", (arg) => {
                    console.log(self['$treenode'].type.name, 'ping', arg)
                })
        }
    }))
export default neutronSIO