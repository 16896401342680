import React from "react"
import Join from "./views/Join"
import Login from "./views/Login"
import Logout from "./views/Logout"
import ResetPassword from "./views/ResetPassword"
import {Outlet} from "react-router-dom"
import everything from "../../../store"

export const ssoRoutes = {
    element: <Outlet/>,
    children: [
        {
            path: 'login',
            element: <Login submit={everything.neutron.sso.login} redirect={'/'}/>,
        },
        {
            path: 'join',
            element: <Join submit={everything.neutron.sso.join} redirect={'/'}/>,
        },
        {
            path: 'logout',
            element: <Logout submit={everything.neutron.sso.logout} redirect={'/'}/>,
        },
        {
            path: 'reset',
            element: <ResetPassword/>,
        }
    ]
}