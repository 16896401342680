import {addMiddleware} from "mobx-state-tree"

const callModelName = (call) => call.context['$treenode'].type.name

export const entanglement_SIO_SSO = everything => {
    addMiddleware(everything, (call, next) => {
            if (call.name === 'initialize') {
                return next(call, result => {
                    let token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
                    const {context: {$treenode}} = call
                    console.log($treenode.type.name, 'entanglementNeutronSIOConnect', everything.neutron.sio.host)
                    token && everything.neutron.sio.sioConnect(token)
                    return result
                })
            }
            return next(call)
        }
    )
}

export const sioAfterCreate = (everything, callback) => {
    addMiddleware(everything, (call, next) => {
            const {name, tree: everything} = call
            if (name === 'sioConnect')
                return next(call, () => {
                    const {neutron: {sio: client}} = everything
                    console.log(call.context['$treenode'].type.name, 'sioAfterCreate', call)
                    callback(client, everything)
                })
            return next(call)
        }
    )
}
export const sioAfterConnect = (everything, callback) => {
    addMiddleware(everything, (call, next) => {
        if (call.name === 'sioConnect') {
            return next(call, (result) => {
                everything.neutron.sio.client.on('connect', () => {
                    const callbackResult = callback(everything.neutron.sio.client, everything)
                    console.log(callModelName(call), 'AfterConnect', callbackResult)
                })
                console.log(callModelName(call), 'sioAfterConnect', result)
                return
            })
        }
        return next(call)
        }
    )
}

export const sioMiddleware = (store, actionsCallbacks) => {
    addMiddleware(store, (call, next) => {
            const {name, tree, args, context} = call
            const act = actionsCallbacks.filter(a => a.action === name)
            if (act.length) {
                const action = act[0]
                // console.log( call.context.$treenode.type.name)
                if (action.model === context.$treenode.type.name) {
                    if (typeof action.before === 'function') {
                        // console.log("item.before")
                        action.before({client: tree['neuron'].sio, store: tree, args: args, instance: context})
                    }
                    if (typeof action.after === 'function') {
                        // console.log("item.after")
                        return next(call, result => {
                            action.after({client: tree['neuron'].sio, store: tree, result: result, instance: context})
                            return result
                        })
                    }
                    if (!action)
                        return next(call)
                }
                return next(call)
            }
            return next(call)

        }
    )
}


export const sioIsSubscribed = (sio, channel) => !!sio.listeners(channel).length