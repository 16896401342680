import {closeSnackbar, enqueueSnackbar} from "notistack"
import Button from "@mui/material/Button"
import React, {useCallback, useEffect, useState} from "react"
import {inject, observer} from "mobx-react"
import {Alert, Modal} from "@mui/material"
import Box from "@mui/material/Box"


const PWA = ({pwa: {newVersionExist, updateVersion}, everything: {neutron: {sio: {sioConnect, sioDisconnect}}}}) => {
    const [blocked, setBlocked] = useState(false)
    const action = useCallback(snackbarId => <>
        <Button onClick={updateVersion}>
            Обновить
        </Button>
        <Button onClick={() => closeSnackbar(snackbarId)}>
            Отмена
        </Button>
    </>, [updateVersion])
    const handleStorageChange = useCallback(e => {
        if (e.key === process.env.REACT_APP_ACCESS_TOKEN)
            e.newValue ?
                setTimeout(() => window.location.href = '/', 1000) :
                window.location.reload()
        else if (e.key === 'tab') {
            setBlocked(true)
            sioDisconnect()
        }
    }, [sioDisconnect])

    useEffect(() => {
        localStorage.setItem('tab', new Date().getTime().toString())
        window.addEventListener('storage', handleStorageChange)
        return () => {
            window.removeEventListener('storage', handleStorageChange)
        }
    }, [handleStorageChange])

    const handleOnClose = () => {
        window.removeEventListener('storage', handleStorageChange)
        setBlocked(false)
        localStorage.setItem('tab', new Date().getTime().toString())
        window.addEventListener('storage', handleStorageChange)
        sioConnect(localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN))
    }

    useEffect(() => {
        if (newVersionExist)
            enqueueSnackbar('Обновите приложение', {action, variant: "info", persist: true})
    }, [newVersionExist, action])

    return <Modal open={blocked}>
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }}>
            <Alert severity="info">Запущен другой экземпляр приложения!</Alert>
            <Button
                sx={{mt: 2}}
                onClick={handleOnClose}
                fullWidth
                variant={'contained'}
            >
                Использовать здесь
            </Button>
        </Box>
    </Modal>
}
export default inject('pwa', 'everything')(observer(PWA))