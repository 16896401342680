import {themeColor} from "../palette"

// console.log(themeColor.typography)

const typography = {
    typography: {
        h1: {
            color: themeColor.palette.primary.dark,
            [themeColor.breakpoints.up('md')]: {
                fontSize: '2rem',
            },
            [themeColor.breakpoints.down('md')]: {
                fontSize: '1rem',
            },
            [themeColor.breakpoints.down('xs')]: {
                fontSize: '0.5rem',
            },
        },
        subtitle1: {
            color: themeColor.palette.primary.light,
            [themeColor.breakpoints.up('md')]: {
                fontSize: '1.4rem',
            }
        },
        subtitle2: {
            color: themeColor.palette.secondary.dark,
            [themeColor.breakpoints.up('md')]: {
                fontSize: '1.4rem',
            }
        },
        //     "body1": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 400,
//         "fontSize": "1rem",
//         "lineHeight": 1.5,
//         "letterSpacing": "0.00938em"
//     },
        body1: {
            [themeColor.breakpoints.up('md')]: {
                fontSize: '1rem',
            },
            [themeColor.breakpoints.down('md')]: {
                fontSize: '0.875rem'
            }
        },
    }
}
// {
//     "htmlFontSize": 16,
//     "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//     "fontSize": 14,
//     "fontWeightLight": 300,
//     "fontWeightRegular": 400,
//     "fontWeightMedium": 500,
//     "fontWeightBold": 700,
//     "h1": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 300,
//         "fontSize": "6rem",
//         "lineHeight": 1.167,
//         "letterSpacing": "-0.01562em"
//     },
//     "h2": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 300,
//         "fontSize": "3.75rem",
//         "lineHeight": 1.2,
//         "letterSpacing": "-0.00833em"
//     },
//     "h3": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 400,
//         "fontSize": "3rem",
//         "lineHeight": 1.167,
//         "letterSpacing": "0em"
//     },
//     "h4": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 400,
//         "fontSize": "2.125rem",
//         "lineHeight": 1.235,
//         "letterSpacing": "0.00735em"
//     },
//     "h5": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 400,
//         "fontSize": "1.5rem",
//         "lineHeight": 1.334,
//         "letterSpacing": "0em"
//     },
//     "h6": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 500,
//         "fontSize": "1.25rem",
//         "lineHeight": 1.6,
//         "letterSpacing": "0.0075em"
//     },
//     "subtitle1": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 400,
//         "fontSize": "1rem",
//         "lineHeight": 1.75,
//         "letterSpacing": "0.00938em"
//     },
//     "subtitle2": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 500,
//         "fontSize": "0.875rem",
//         "lineHeight": 1.57,
//         "letterSpacing": "0.00714em"
//     },
//     "body1": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 400,
//         "fontSize": "1rem",
//         "lineHeight": 1.5,
//         "letterSpacing": "0.00938em"
//     },
//     "body2": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 400,
//         "fontSize": "0.875rem",
//         "lineHeight": 1.43,
//         "letterSpacing": "0.01071em"
//     },
//     "button": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 500,
//         "fontSize": "0.875rem",
//         "lineHeight": 1.75,
//         "letterSpacing": "0.02857em",
//         "textTransform": "uppercase"
//     },
//     "caption": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 400,
//         "fontSize": "0.75rem",
//         "lineHeight": 1.66,
//         "letterSpacing": "0.03333em"
//     },
//     "overline": {
//         "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
//         "fontWeight": 400,
//         "fontSize": "0.75rem",
//         "lineHeight": 2.66,
//         "letterSpacing": "0.08333em",
//         "textTransform": "uppercase"
//     }
// }
export default typography