import {Api, Computer, Public, Smartphone, Workspaces} from "@mui/icons-material"

export const infoOrg = [[
    {
        title: 'Браузер',
        route: 'info/browser',
        Icon: Public,
    },
    {
        title: 'Десктоп',
        route: 'info/desktop',
        Icon: Computer,
    },
    {
        title: 'Мобильный',
        route: 'info/mobile',
        Icon: Smartphone,
    },
    {
        title: 'Окружение',
        route: 'info/workspace',
        Icon: Workspaces,
    },
    {
        title: 'Интеграция',
        route: 'info/api',
        Icon: Api,
    }
]]
export const organismInfo = {
    'browser': {
        path: 'browser',
        description: `
            Наша платформа позволяет создавать и управлять ботами, которые работают в браузере.
            Такие боты могут выполнять различные задачи, такие как
            автоматизация веб-форм, сбор данных и многое другое.`
    },
    'mobile': {
        path: 'mobile',
        description: `
            Наша платформа позволяет создавать и управлять ботами для работы на мобильных устройствах.
            Такие боты могут выполнять задачи, связанные с приложениями на мобильных устройствах, сбор данных и многое другое.
            Мы поддерживаем широкий спектр мобильных устройств, таких как iOS и Android.`
    },
    'desktop': {
        path: 'desktop',
        description: `
            Мы предоставляем возможность создания ботов для работы на рабочем столе.
            Такие боты могут автоматизировать рутинные задачи, управлять файлами и папками, выполнять задачи связанные с приложениями, и многое другое.
            Наши боты поддерживают операционные системы Windows, MacOS и Linux.`
    },
    'workspace': {
        path: 'workspace',
        description: `
            Мы предоставляем возможность создания и управления виртуальными рабочими столами для ботов.
            Это позволяет создавать изолированные рабочие среды для ботов и управлять ими из облачной платформы.
            Такой подход позволяет максимально оптимизировать использование ресурсов и повышает безопасность.`
    },
    'api': {
        path: 'api',
        description: `
            Мы предоставляем широкие возможности для интеграции ботов в различные системы и платформы.
            Наши боты могут интегрироваться с CRM системами, электронными почтовыми сервисами, системами управления контентом и многими другими.
            Кроме того, мы предоставляем API для интеграции с пользовательскими приложениями и системами.`
    }
}